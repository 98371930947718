import React, { useState } from "react";

import Tutorial from "../Tutorial/Tutorial";

import "./Header.css";

export default function Header() {
    let initialShowTutorial = true;
    if (document.cookie.match(/^(.*;)?\s*played\s*=\s*[^;]+(.*)?$/) != null) {
        initialShowTutorial = false;
    }
    const [ showTutorial, setShowTutorial ] = useState(initialShowTutorial);
    
    return (
        <div className={showTutorial ? "tutorialContainer" : "standardContainer"}>
          <div className="headerContainer">
            <p className="pointer" onClick={() => setShowTutorial(true)}>❓</p>
            
            <p className="headerTitle">🐘  Jumbordle</p>
            
            <p className={showTutorial ? "pointer cross" : "invisible"} 
               onClick={() => setShowTutorial(false)}>❌</p>
            <a href="https://campusmaps-new.tufts.edu/docs/Tufts_Medford_Directory.pdf" 
               target="_blank" rel="noreferrer" className="mapLink">
                <div className={showTutorial ? "invisible" : "pointer"}>🗺</div>
            </a>
          </div>
          <div className={showTutorial ? "tutorialContainer" : "invisible"}>
            <Tutorial setShowTutorial={setShowTutorial}/>
          </div>
          <p className={ showTutorial ? "invisible" : "rollBos"}>roll 'bos</p>
        </div>
    )
}