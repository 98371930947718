import React from "react";

import "./Guess.css";

// determined colors between , "#df7373 (red) and , "#9cdf73 (green) using 
// https://colordesigner.io/gradient-generator
const colorRange = [
    "#9cdf73", "#a7d969", "#b1d260", "#bacc59", "#c3c554", "#cabe50",
    "#d0b74d", "#d6b04d", "#dba94d", "#dfa34f", "#e29c51", "#e49555",
    "#e68e59", "#e6885e", "#e68263", "#e57d68", "#e2786e", "#df7373"
]

//return relevant arrow direction based on columnDist and rowDist
export function Arrow(props) {
    const columnDist = props.columnDist;
    const rowDist = props.rowDist;
    if (columnDist < 0) {
        if (rowDist < 0) {
            return <p>↖️</p>;
        } else if (rowDist === 0) {
            return <p>⬅️</p>;
        } else if (rowDist > 0) {
            return <p>↙️</p>;
        }
    } else if (columnDist === 0) {
        if (rowDist < 0) {
            return <p>⬆️</p>;
        } else if (rowDist === 0) {
            return <p>⏺</p>;
        } else if (rowDist > 0) {
            return <p>⬇️</p>;
        }
    } else if (columnDist > 0) {
        if (rowDist < 0) {
            return <p>↗️</p>;
        } else if (rowDist === 0) {
            return <p>➡️</p>;
        } else if (rowDist > 0) {
            return <p>↘️</p>;
        }
    }
}

export function ColDist(props) {
    return props.answer.code.charCodeAt(0) - props.code.charCodeAt(0);
}

export function RowDist(props) {
    let answerRow = parseInt(props.answer.code.substring(1));
    let guessRow = parseInt(props.code.substring(1));
    return answerRow - guessRow;
}

export function PercentageDist(props) {
    let columnDist = ColDist(props);
    let rowDist = RowDist(props);
    let straightDist = Math.floor(Math.sqrt(columnDist * columnDist + rowDist * rowDist));
    let percentageDist = Math.floor(100 - (100 / 17 * straightDist));
    
    if (percentageDist === 100 && props.answer.name !== props.name) {
        percentageDist -= 3;
    }
    
    return percentageDist;
}

export default function Guess(props) {
    
    if (props.guessed) {
        //determine the distance along the column from guess to answer
        let columnDist = props.answer.code.charCodeAt(0) - props.code.charCodeAt(0);
        
        //determine the distance along the row from guess to answer
        let answerRow = parseInt(props.answer.code.substring(1));
        let guessRow = parseInt(props.code.substring(1));
        let rowDist = answerRow - guessRow;
        
        // determine distance "as crow flies" using pythagorean theorem
        let straightDist = Math.floor(Math.sqrt(columnDist * columnDist + rowDist * rowDist));
        let percentageDist = Math.floor(100 - (100 / 17 * straightDist));
        
        if (percentageDist === 100 && props.answer.name !== props.name) {
            percentageDist -= 3;
        }
        
        return (
            <div className="guessContainer guessedContainer">
                <div className={props.answer.name === props.name ? 
                     "correct guessName guessed" : "guessName guessed"}>
                    <p className="guessText">{props.name}</p>
                </div>
                <div className={props.answer.classification === props.classification ?
                            "correct guessClass guessed" : "guessClass guessed"}>
                    <p className="guessText">{props.classification}</p>
                </div>
                <div className="guessArrow guessed guessFlex"
                     style={ percentageDist >= 97 ? {backgroundColor: "#9cdf73"}: 
                             {backgroundColor: "white"}}>
                    <Arrow columnDist={columnDist} rowDist={rowDist}/>
                </div>
                <div className="guessDistance guessed"
                     style={{backgroundColor: colorRange[straightDist]}}>
                    <p className="guessText">{percentageDist}%</p>
                </div>
            </div>
        )
    }
    
    return (
        <div className="guessContainer unguessed"/>
    )
}