import './App.css';
import "./components/Input/Input.css";

import Header from "./components/Header/Header";
import Guess from "./components/Guess/Guess";
import { Arrow, RowDist, ColDist, PercentageDist } from "./components/Guess/Guess";

import { useState, useEffect } from "react";

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'bfbab6a5-b8ab-4491-896a-a1922686f293',
    clientToken: 'pub540f4f91d0d4cb18070fc87e48e067f5',
    site: 'datadoghq.eu',
    service:'jumbordle',
    env:'prod',
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0', 
    sessionSampleRate:100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'allow'
});
    
datadogRum.startSessionReplayRecording();

const buildingsUnshuffled = require("./txt/allbuildings_unshuffled.txt");
const buildingsShuffled = require("./txt/allbuildings_shuffled.txt");

function daysIntoYear(date) {
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
            - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
}

export function shareLine(percentageDist, direction, won) {
    let result = "";
    let numGreen = Math.floor(percentageDist / 20);
    let numYellow = Math.floor((percentageDist - numGreen * 20) / 10);

    let i = 0;
    while (i < 5) {
        if (numGreen !== 0) {
            result += "🟩";
            numGreen--;
        } else if (numYellow !== 0) {
            result += "🟨";
            numYellow--;
        } else {
            result += "⬛️"
        }
        i++;
    }
    
    result += (percentageDist === 100 ? "🎉" : direction);
    
    return result + "\n";
}

function App() {
  
  const [ numGuesses, setNumGuesses ] = useState(0);
  const [ inputState, setInput ] = useState("");
  const [ guesses, setGuesses ] = useState([]);
  
  const [ won, setWon ] = useState(0); // 0: playing, 1: won, 2: lost
  const [ shareString, setShareString ] = useState("");
  const [ copied, setCopied ] = useState(false);
  
  const [ allUnshuffledBuildings, setAllUnshuffledBuildings ] = useState("");
  const [ allShuffledBuildings, setAllShuffledBuildings ] = useState("");
  
  useEffect(() => {
      fetch(buildingsUnshuffled)
          .then(response => response.text())
          .then(text => setAllUnshuffledBuildings(text))
      fetch(buildingsShuffled)
          .then(response => response.text())
          .then(text => setAllShuffledBuildings(text))
  }, []);
  
  let buildings = [];
  let splitTextFileArr = allUnshuffledBuildings.split(/ - |\n/);
  
  for (let i = 0; i < splitTextFileArr.length - 3; i += 3) {
      let building = { name: splitTextFileArr[i], 
                       classification: splitTextFileArr[i + 1], 
                       code: splitTextFileArr[i + 2] };
      buildings.push(building);
  }
  
  let shuffledBuildings = [];
  splitTextFileArr = allShuffledBuildings.split(/ - |\n/);
  
  for (let i = 0; i < splitTextFileArr.length - 3; i += 3) {
      let building = { name: splitTextFileArr[i], 
                       classification: splitTextFileArr[i + 1], 
                       code: splitTextFileArr[i + 2] };
      shuffledBuildings.push(building);
  }
  
  let today = new Date();
  let todaynum = daysIntoYear(today) % buildings.length;
  
  let answer = { name: "Null", classification: "Null", code: "A1"};
  answer = shuffledBuildings[todaynum];
  
  const caseInsensitiveInputState = new RegExp(inputState, "i");
  
  if (won !== 0) {
      document.cookie = "played=true";
  }
  
  return (
    <div className="App">
      <Header />
      <div className="guesses">
        <Guess 
          guessed={numGuesses >= 1} answer={answer} 
          name={numGuesses >= 1 ? guesses[0].name : undefined} 
          classification={numGuesses >= 1 ? guesses[0].classification : undefined} 
          code={numGuesses >= 1 ? guesses[0].code : undefined}
        />
        <Guess 
          guessed={numGuesses >= 2} answer={answer} 
          name={numGuesses >= 2 ? guesses[1].name : undefined} 
          classification={numGuesses >= 2 ? guesses[1].classification : undefined}
          code={numGuesses >= 2 ? guesses[1].code : undefined}
        />
        <Guess 
          guessed={numGuesses >= 3} answer={answer} 
          name={numGuesses >= 3 ? guesses[2].name : undefined} 
          classification={numGuesses >= 3 ? guesses[2].classification : undefined} 
          code={numGuesses >= 3 ? guesses[2].code : undefined}
        />
        <Guess 
          guessed={numGuesses >= 4} answer={answer} 
          name={numGuesses >= 4 ? guesses[3].name : undefined} 
          classification={numGuesses >= 4 ? guesses[3].classification : undefined} 
          code={numGuesses >= 4 ? guesses[3].code : undefined}
        />
        <Guess 
          guessed={numGuesses >= 5} answer={answer} 
          name={numGuesses >= 5 ? guesses[4].name : undefined} 
          classification={numGuesses >= 5 ? guesses[4].classification : undefined} 
          code={numGuesses >= 5 ? guesses[4].code : undefined}
        />
        <Guess 
          guessed={numGuesses >= 6} answer={answer} 
          name={numGuesses >= 6 ? guesses[5].name : undefined} 
          classification={numGuesses >= 6 ? guesses[5].classification : undefined} 
          code={numGuesses >= 6 ? guesses[5].code : undefined}
        />
      </div>
      <div className="options"> {/* see line 151 if you want this to disappear on gameover*/}
        {(buildings.filter(building => building.name.match(caseInsensitiveInputState))).map(building => {
                return (
                    <p className="option"
                    key={building.name} name={building.name} 
                       onClick={() => {
                           const buildingOption = building.name;
                           return setInput(buildingOption);
                       }}>{building.name}</p>
                );
            })
        }
      </div>
      <div className="inputContainer">
        {/* if you want it to disappear on gameover, replace above with
            <div className={ won > 0 ? "invisible inputContainer" : "inputContainer"}> */}
         <input type="text" className="inputForm" value={inputState} 
                placeholder="Start typing here to filter the options :)"
                onClick={() => setInput("") } 
                onChange={(e) => setInput(e.target.value)} />
      </div>
      <div className={ won > 0 ? "invisible inputContainer button" : 
                             "inputContainer button" }
           onClick={() => {
               let newGuess = { name: "", classification: "", code: "" };
               let validInput = false;
               
               for (let i = 0; i < buildings.length; i++) {
                   if (buildings[i].name === inputState) {
                       newGuess.name = buildings[i].name;
                       newGuess.classification = buildings[i].classification;
                       newGuess.code = buildings[i].code;
                       validInput = true;
                   }
               }
               
               for (let i = 0; i < guesses.length; i++) {
                   if (guesses[i].name === inputState) {
                        validInput = false;
                   }
               }
               
               if (!validInput) return;
               if (numGuesses === 5) {
                   setWon(2); // lost
               } else {
                   setInput(""); // resets input so all options show
               }
               setNumGuesses(numGuesses + 1);
               setGuesses([...guesses, newGuess]);
               if (newGuess.name === answer.name) {
                   setWon(1); // won
               }
               
               let distInput = { answer: answer, code: newGuess.code, name: newGuess.name }
               let arrowInput = { columnDist: ColDist(distInput), 
                                  rowDist: RowDist(distInput)}
               let percentageDist = PercentageDist(distInput);

               setShareString(shareString + shareLine(percentageDist, Arrow(arrowInput).props.children, false));
              //  if (won == 0) setInput(""); 
           }}>
          <p className="inputSubmit">GUESS</p>
      </div>
      <div className={won === 1 ? "inputContainer congrats" : "invisible"}>
        <p className="inputSubmit">CONGRATS 🎉🎉🎉</p>
      </div>
      <div className={won === 2 ? "unlucky" : "invisible"}>
        <p className="inputSubmit">BETTER LUCK NEXT TIME :/</p>
        <p>The answer was: <strong>{ answer === undefined
                                       ? "" : answer.name}</strong></p>
      </div>
      <div className={ won !== 0 ? "share" : "invisible"}
           onClick={() => {
               if (won === 1) {
                   navigator.clipboard.writeText("Jumbordle (" + numGuesses + "/6):\n"
                                                + shareString + "jumbordle.com");
               } else {
                   navigator.clipboard.writeText("Jumbordle (X/6):\n"
                                                + shareString + "jumbordle.com");
               }
                setCopied(true);
           }}>
        <p className="inputSubmit">SHARE</p>
      </div>
      <p className={copied ? "copied" : "invisible"}>Copied results!</p>
    </div>
  );
}

export default App;