import React from "react";

import Guess from "../Guess/Guess";

import "./Tutorial.css"
import "../Input/Input.css"

export default function Tutorial({setShowTutorial}) {
    
    const exampleAnswer = { name: "Joyce Cummings Center", classification: "Academic", code: "H5" };
    
    return (
      <div className="container">
         <div className="tutorial topSection">
            <p>You have 6 guesses to guess the 
               <strong className="jumbordle"> Jumbordle</strong>. 
               Each guess must be a 
               valid building/site on the medford/somerville campus.</p>
            <p>After each guess, you'll see whether the classification was 
               right and how close the guess was to the correct building.</p>
               <p>A new <strong className="jumbordle">Jumbordle </strong> 
               will be available every day!</p>
         </div>
         <div className="horizontalLine"/>
         <div className="tutorial middleSection">
            <h4>Example</h4>
            <Guess guessed={true} answer={exampleAnswer} name="Haskell Hall"
                   classification="Dorm" code="F11"/>
            <p>Your first guess, <strong>Haskell Hall</strong>, is pretty far 
               from the target, which itself isn't a dorm, and is north-east 
               of Haskell. </p>
            <Guess guessed={true} answer={exampleAnswer} name="Halligan Hall"
                   classification="Academic" code="H4"/>
            <p>Your second guess, <strong>Halligan Hall</strong>, is much 
               closer to the target building, which is also an academic 
               building and south of Halligan.</p>
            <Guess guessed={true} answer={exampleAnswer} name="Joyce Cummings Center"
                   classification="Academic" code="H5"/>
            <p>Your third guess, <strong>Joyce Cummings Center</strong>, 
               was correct! Congrats!</p>
         </div>
         <div className="horizontalLine"/>
         <p className="startContainer button" 
            onClick={() => {setShowTutorial(false)}}>Start playing!</p>
         <div className="tutorial">
             <p><strong className="jumbordle"> Jumbordle</strong> was 
                significantly inspired by Wordle by Josh Wardle 
                (@powerlanguish) and Worldle by @teuteuf</p>
         </div>
         <div className="tutorial credits">
            <p className="creditsText">Created by Lucas Maley, A24</p>
         </div>
      </div>
    )
}